/* You can add global styles to this file, and also import other style files */
@use './colors.scss';
@import '../../node_modules/@fortawesome/fontawesome-pro/css/all.css';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400&family=Rajdhani:wght@400;500;700&display=swap');
@import '../../node_modules/@swimlane/ngx-datatable/index.css';
@import '../../node_modules/@swimlane/ngx-datatable/themes/material.css';
@import '../../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import '~jsoneditor/dist/jsoneditor.min.css';
@import './bs-overrides.scss';
@import '../../node_modules/bootstrap/scss/bootstrap';
@import './assets/css/mixins-local';
@import '~@ng-select/ng-select/themes/material.theme.css';
@import '../../node_modules/ag-grid-community/styles/ag-grid.css';
@import '../../node_modules/ag-grid-community/styles/ag-theme-quartz.css';
@import '../../h20-shared/shared-styles.scss';
@import '@angular/cdk/overlay-prebuilt.css';
@import '@fontsource-variable/roboto-flex/standard.css';

.ng-invalid {
    @extend .is-invalid;
}

:root {
    --main-bg-color: #f2f3f3;
    --orange-accessible: #cb4e1c;
    /* Darker than NKF Orange for Accessibility */
    --green-accessible: #248440;
    /* Accessibility */
    --blue-accessible: #0072ed;
    /* Accessibility */
    --pulse-green: #33a02c;
    // --pulse-green-hover: #33a02c;
    --pulse-green-hover: rgba(51, 160, 44, 0.15);
    --pulse-warning: #cc3333;
    /* Previous EE2E3B */
    --pulse-danger: #b10000;
    /* Accessible Level AAA for red text on white and H20 gray */
    --pulse-pending: #707070;
    --pulse-unmatched: #ff7f00;
    --pulse-dv-blue: #0072ed;
    --pulse-dv-blue-rgb: 0, 72, 237;
    /* Previous 1F78B4 */
    --pulse-dv-red: #e31a1c;
    --pulse-dv-green: #33a02c;
    --pulse-dv-orange: #ff7f00;
    --pulse-dv-orange-opacity: rgba(255, 127, 0, 0.5);
    --pulse-dv-purple: #6a3d9a;
    --color-white: #ffffff;
    --color-white-rgb: 255, 255, 255;
    --color-grey-fc: #fcfcfc;
    --color-grey-ef: #efefef;
    --color-grey-e1: #e1e1e1;
    --color-grey-df: #dfdfdf;
    --color-grey-c8: #c8c8c8;
    --color-grey-b3: #b3b3b3;
    --color-grey-af: #afafaf;
    --color-grey-87: #878787;
    --color-grey-70: #707070;
    --color-grey-5a: #5a5a5a;
    --color-grey-3c: #3c3c3c;
    --color-black: #000000;
    --font-family: 'Roboto Flex Variable', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    --font-family-standard: 'Arial', 'Helvetica', sans-serif;
    --font-family-headings: 'Rajdhani', 'Helvetica', 'Arial', sans-serif;
    --spacer: 4px;
    --trans: 0.3s;
    --ease-in-out-2s: all 0.2s ease-in-out;
    --cursor-pointer: pointer;
    --cursor-auto: auto;
    --progress-info: #73a4dc;
    --progress-filled: #316fb5;
    --progress-error: #f2a59d;
    --progress-border: #fff;
    --progress-empty: #dedede;
    --progress-dot: #a1a1a1;
    --progress-active-dot: #616161;
    --client-accent-color1: #0576bd;
    --client-accent-color1a: #0576bd;
    --client-accent-color1b: #0576bd;
    --client-accent-color2: #0576bd;
}

body {
    background-color: var(--main-bg-color);
    font-family: var(--font-family);
    font-size: var(--font-size-18);
    @include tablet {
        font-size: 0.75rem;
    }
    &.public-view {
        height: 100%;
        font-family: var(--font-family-standard);
        /* background-image: url('assets/image/backgrounds/bg-nkf.png'); This should be set in the Theme // NKF20-38 */
        .formbit-layout {
            /* ".formbit-layout" relevant to newer H20 Login, Activate and forgot Password */
            .container-fluid {
                position: absolute;
                top: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                .thebg {
                    padding: 30px;
                    /* background-color:var(--color-white); This should be set in the Theme // NKF20-38 */
                    .login-style {
                        #languageselector {
                            /* TEMP style only */
                            font-size: var(--font-size-10);
                        }
                        h1 {
                            font-family: var(--font-family-headings);
                            font-weight: 600;
                            font-size: var(--font-size-26);
                            letter-spacing: -1px;
                        }
                        /* 
                        a.text-dark {
                            &:hover {
                                color: var(--client-accent-color2) !important;
                                transition: var(--trans) !important; 
                            }
                        }
                        */
                        button {
                            width: 100%;
                            &.btn-login {
                                font-family: var(--font-family-headings);
                                font-weight: 500;
                            }
                            &.btn-client-accent {
                                background-color: var(--orange-accessible);
                                border-color: var(--orange-accessible);
                            }
                        }
                        img {
                            max-width: 90%;
                        }
                    }
                }
            }
        }
    }
    /* End .public-view */
}

/* Utility Classes */

.bg-none {
    background: none !important;
}

.cursor-auto {
    cursor: var(--cursor-auto);
}

/* ##### BOOTSTRAP OVERRIDES ##### */

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    max-width: 1400px;
}

.text-danger {
    color: var(--pulse-danger) !important;
}

.accordion-button-active-bg {
    color: var;
}

.accordion-button:not(.collapsed) {
    color: var(--color-black);
    background-color: var(--color-white);
}

.form-check-input:checked[type='radio'] {
    background-image: none;
}

.form-check-input:checked[type='radio'] {
    background-color: var(--pulse-dv-blue);
    border-color: var(--pulse-dv-blue);
}

.form-check-label {
    padding-left: 4px;
    &:hover {
        transition: var(--ease-in-out-2s);
        background-color: rgba(var(--pulse-dv-blue-rgb), 0.1);
        border-radius: 5px;
        cursor: var(--cursor-pointer);
    }
}

/* ##### GLOBAL ##### */

.color-client-accent-1 {
    color: var(--client-accent-color1);
}

.color-client-accent-2 {
    color: var(--client-accent-color2);
}

// These will get moved for theming purposes... perhaps.
.border-radius-4 {
    border-radius: 4px;
}

.border-color-EF {
    border: solid 1px #efefef;
}

.border-color-E1 {
    border-bottom: solid 1px var(--color-grey-e1);
}

.border-color-C8 {
    border: solid 1px var(--color-grey-c8);
}

.border-d-color-70 {
    border: dotted 1px var(--color-grey-70);
}

.bg-color-FF {
    background-color: var(--color-white);
}

.bg-color-FC {
    background-color: var(--color-grey-fc);
}

/* .color-5A { color: #5A5A5A; } */

.color-1E {
    color: #1e1e1e;
}

.font-size-32 {
    font-size: 2rem;
}

.font-size-26 {
    font-size: 1.625rem;
}

.font-size-24 {
    font-size: 1.5rem;
}

.font-size-21 {
    font-size: 1.3125rem;
}

.font-size-18 {
    font-size: 1.125rem;
}

.font-size-12 {
    font-size: var(--font-size-12);
}
.font-size-10 {
    font-size: var(--font-size-10);
}

.pm-x {
    margin-left: 1.875rem;
    margin-right: 1.875rem;
}

.font-family-headers {
    font-family: var(--font-family-headings);
    font-weight: 500;
}

.font-family-deco {
    font-family: var(--font-family-headings);
    font-weight: 400;
}

/* No longer used in H20. The class "pulse-container" became the Bootstrap "container" in Dec 2019 - RT
.pulse-container {
    background-color: #ffffff;
    margin: 1.875rem 1.875rem;
    padding: 1.25rem;
    @extend .border-radius-4;
    @extend .border-color-C8;

    h1 {
        @extend .font-family-headers;
        @extend .color-5A;
        font-size: var(--font-size-32);
        letter-spacing: -1px;
        @include tablet {
            font-size: var(--font-size-18);
        }
    }

    h2 {
        @extend .font-family-headers;
        @extend .color-5A;
        @extend .font-size-26;
        letter-spacing: -1px;
    }
}
*/

.container,
.container-fluid {
    /* Use: My Library > Right Panel (Selected Category List AND Search bar) */
    &.panel-control {
        margin-top: 18px;
    }
    .card-info-page {
        h1 {
            /* <h1> here is presently used on Patient's page, otherwise all others are used in My Library (at present) */
            @extend .font-family-headers;
            color: var(--color-grey-5a);
            font-size: var(--font-size-26);
            /* RT WAS --font-size-32 */
            @include tablet {
                font-size: var(--font-size-21);
                /* RT WAS --font-size-24 */
            }
        }
        h2 {
            @extend .font-family-headers;
            color: var(--color-grey-5a);
            font-size: var(--font-size-24);
            &.cta-copy {
                font-size: 1.9rem;
                @media (max-width: 1199px) {
                    font-size: var(--font-size-21);
                }
                @media (max-width: 575.98px) {
                    font-size: var(--font-size-18);
                }
            }
            &.cta-header {
                color: var(--color-black);
                font-size: 3.1rem;
                margin-left: 10%;
                @media (max-width: 1199px) {
                    font-size: var(--font-size-24);
                    margin-left: 20%;
                }
                @media (max-width: 575.98px) {
                    font-size: var(--font-size-21);
                    margin-left: 20%;
                }
            }
            /* RT WAS --font-size-26 */
            @include tablet {
                font-size: var(--font-size-18);
            }
            &.search-init {
                float: right;
            }
            &#search-library {
                display: none !important;
            }
            /* REMOVE this line at future date when Library Search feature is added back in. */
        }
        p {
            &.cta-copy {
                font-size: 1.9rem;
                margin-left: 10%;
                @media (max-width: 1199px) {
                    font-size: var(--font-size-21);
                    margin-left: 20%;
                }
                @media (max-width: 575.98px) {
                    font-size: var(--font-size-18);
                    margin-left: 20%;
                }
            }
        }
        ul {
            margin-top: 15px;
        }
    }
    .card {
        h1 {
            /* <h1> here is presently used on Patient's page, otherwise all others are used in My Library (at present) */
            @extend .font-family-headers;
            color: var(--color-grey-5a);
            font-size: var(--font-size-26);
            /* RT WAS --font-size-32 */
            @include tablet {
                font-size: var(--font-size-21);
                /* RT WAS --font-size-24 */
            }
        }
        h2 {
            @extend .font-family-headers;
            color: var(--color-grey-5a);
            font-size: var(--font-size-24);
            &.cta-copy {
                font-size: 1.9rem;
                @media (max-width: 1199px) {
                    font-size: var(--font-size-21);
                }
                @media (max-width: 575.98px) {
                    font-size: var(--font-size-18);
                }
            }
            &.cta-header {
                color: var(--color-black);
                font-size: 3.1rem;
                @media (max-width: 1199px) {
                    font-size: var(--font-size-24);
                }
                @media (max-width: 575.98px) {
                    font-size: var(--font-size-21);
                }
            }
            /* RT WAS --font-size-26 */
            @include tablet {
                font-size: var(--font-size-18);
            }
            &.search-init {
                float: right;
            }
            &#search-library {
                display: none !important;
            }
            /* REMOVE this line at future date when Library Search feature is added back in. */
        }
        p {
            &.cta-copy {
                font-size: 1.9rem;
                @media (max-width: 1199px) {
                    font-size: var(--font-size-21);
                }
                @media (max-width: 575.98px) {
                    font-size: var(--font-size-18);
                }
            }
        }
        ul {
            margin-top: 15px;
        }
    }
    .input-search {
        @extend .font-family-headers;
    }
    /* Use: My Library > Left Panel (Categories) */
    .list-group {
        h1 {
            @extend .font-family-headers;
            color: var(--color-grey-5a);
            @extend .font-size-32;
        }
        li {
            &.list-group-item {
                i {
                    &.fa-circle {
                        color: var(--client-accent-color2);
                    }
                }
            }
        }
    }
}

h1 {
    /* Needed for control outside of .card - RT */
    &.h1-utility {
        @include h1-gray;
    }
}

#collapseSearch {
    select {
        height: 50px !important;
        -moz-appearance: none;
        -webkit-appearance: none;
        cursor: var(--cursor-pointer);
        background: var(--main-bg-color);
    }
    button {
        background: var(--main-bg-color);
    }
}

.fa-info-circle {
    /* Icon Hover control */
    transition: 0.4s;
    &:hover {
        transition: 0.4s;
        color: var(--client-accent-color2);
    }
}

/*
.header_logo {
    width: 282px;
    height: 40px;
    // background-image: url('assets/image/generic_registry.png');
    background-image: url('assets/image/logos/test_logo.png');
    background-position: left top;
    background-size: contain;
    background-repeat: no-repeat;
}
*/

// .pif-logo {
//     width: 41px;
//     height: 40px;
//     background-image: url('assets/image/footer-globe.png');
//     background-position: left top;
//     background-size: cover;
//     background-repeat: no-repeat;
// }

/* ##### NGX-DATATABLE OVERRIDES ##### */

.datatable-row-odd {
    @extend .bg-color-FF;
    @extend .border-color-E1;
}

.datatable-row-even {
    @extend .bg-color-FC;
    @extend .border-color-E1;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell.icon-big {
    font-size: 1.25rem;
    display: block;
    // align-items: right;
    // padding: .9rem 1.2rem;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: var(--font-size-18);
    &.action-cell {
        overflow: visible;
        .dropdown-menu {
            position: absolute;
            /* top: 0; */
            left: 0;
            .dropdown-item:hover {
                cursor: var(--cursor-pointer);
            }
        }
    }
}

/* 1 of 2 - Custom fix for last row of table, and subsequent first-child overide when only one row present. */

.datatable-row-wrapper:last-child .datatable-body-cell {
    .dropdown-menu {
        /* height: 122px; */
        margin-top: -140px;
    }
}

/* 2 of 2 - Custom fix re-instate for first row on load */

.datatable-row-wrapper:first-child .datatable-body-cell {
    .dropdown-menu {
        top: 0px !important;
        margin-top: 0px; // dh - update to first child being rendered behind table header
    }
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell.text-right {
    justify-content: end;
}

.ngx-datatable.material {
    box-shadow: none;
    .icon-big {
        font-size: 1.25rem;
    }
    .datatable-header-cell-label {
        text-transform: uppercase;
        @extend .color-1E;
        font-weight: 700;
    }
}

.ngx-datatable.material-neg {
    min-height: 40rem; // table was becoming too short when few items were in the table - affecting action button dropdown
}

.btn-primary {
    background-color: var(--client-accent-color1) !important; //var(--blue-accessible);
    //border-color: var(--client-accent-color1);
    border: none;
    &:disabled {
        opacity: 0.45;
    }
    &:hover {
        //border-color: var(--client-accent-color1b);
        &:not([disabled]) {
            background-color: var(--client-accent-color1b) !important;
        }
    }
}

.btn-outline-primary {
    border-color: var(--client-accent-color1) !important;
    color: var(--client-accent-color1);
    &:hover {
        background-color: var(--client-accent-color1) !important;
    }
}

.btn-outline-success {
    color: var(--green-accessible);
}

.btn-outline-client1 {
    background-color: var(--client-accent-color1);
    color: var(--color-white);
    &:disabled {
        background: none;
        color: var(--client-accent-color1) !important;
        border-color: var(--client-accent-color1);
        opacity: 0.4;
    }
    &:hover {
        color: var(--color-white);
        &:not([disabled]) {
            background-color: var(--client-accent-color2);
        }
    }
}

.btn-big {
    padding: 1.25rem 2rem;
    text-transform: uppercase;
    background-color: var(--client-accent-color1);
    color: var(--color-white);
    border-radius: 0.1875rem;
    @extend .font-family-headers;
    display: inline-block;
    letter-spacing: 2.5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
    &:disabled {
        opacity: 0.35;
        pointer-events: none;
    }
}

input[type='radio'].with-font,
input[type='checkbox'].with-font {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    display: none;
    //position: absolute; This was making the list of radio/checkboxes render below the screen causing a scroll bar on the parent, and causing the screen to jump down on select.
    width: 1px;
}

.horizontal {
    input[type='radio'].with-font ~ label:before,
    input[type='checkbox'].with-font ~ label:before {
        font-family: 'Font Awesome 5 Pro';
        display: block;
        content: '\f111';
        letter-spacing: 0.25rem;
        font-size: 2rem;
        color: var(--color-grey-af);
        font-weight: 300;
    }
    input[type='radio'].with-font:checked ~ label:before,
    input[type='checkbox'].with-font:checked ~ label:before {
        content: '\f058';
        font-size: 2rem;
        color: #33a02c;
        font-weight: 900;
    }
    input[type='checkbox'].with-font ~ label:before {
        content: '\f096';
    }
    input[type='checkbox'].with-font:checked ~ label:before {
        content: '\f046';
        color: darkgreen;
    }
    input[type='radio'].with-font:focus ~ label:before,
    input[type='checkbox'].with-font:focus ~ label:before,
    input[type='radio'].with-font:focus ~ label,
    input[type='checkbox'].with-font:focus ~ label {
        color: #33a02c;
    }
}

.vertical {
    width: 100%;
    input[type='radio'].with-font ~ label:after,
    input[type='checkbox'].with-font ~ label:after {
        font-family: 'Font Awesome 5 Pro';
        display: block;
        content: '\f111';
        letter-spacing: 0.25rem;
        font-size: 1.75rem;
        color: var(--color-grey-af);
        font-weight: 300;
        // margin-left: 50%;
        position: absolute;
        right: 0;
    }
    input[type='radio'].with-font:checked ~ label:after,
    input[type='checkbox'].with-font:checked ~ label:after {
        content: '\f058';
        color: #33a02c;
        font-weight: 900;
    }
    input[type='radio'].with-font:focus ~ label:after,
    input[type='checkbox'].with-font:focus ~ label:after,
    input[type='radio'].with-font:focus ~ label,
    input[type='checkbox'].with-font:focus ~ label {
        color: #33a02c;
    }
}

html[dir='rtl'] .vertical {
    width: 100%;
    input[type='radio'].with-font ~ label:after,
    input[type='checkbox'].with-font ~ label:after {
        font-family: 'Font Awesome 5 Pro';
        display: block;
        content: '\f111';
        letter-spacing: 0.25rem;
        font-size: 1.75rem;
        color: var(--color-grey-af);
        font-weight: 300;
        // margin-left: 50%;
        position: absolute;
        right: unset;
        left: 8px;
    }
    input[type='radio'].with-font:checked ~ label:after,
    input[type='checkbox'].with-font:checked ~ label:after {
        content: '\f058';
        color: #33a02c;
        font-weight: 900;
    }
    input[type='radio'].with-font:focus ~ label:after,
    input[type='checkbox'].with-font:focus ~ label:after,
    input[type='radio'].with-font:focus ~ label,
    input[type='checkbox'].with-font:focus ~ label {
        color: #33a02c;
    }
}

.verticalLeft {
    width: 35%;
}

.carousel-ctrl {
    width: 40px;
    @media (max-width: 575.98px) {
        width: 70px; // allow for larger clickable region on mobile.
    }
    &.carousel-control-prev {
        border-radius: 0.25rem 0 0 0.25rem;
        margin-right: 1px;
        border-right: 2px solid white;
        left: 0;
        right: unset;
    }
    &.carousel-control-next {
        border-radius: 0 0.25rem 0.25rem 0;
        margin-left: 1px;
        border-left: 2px solid white;
        left: unset;
        right: 0;
    }
    html[dir='rtl'] &.carousel-control-prev {
        border-radius: 0 0.25rem 0.25rem 0;
        margin-right: unset;
        margin-left: 1px;
        border-right: unset;
        border-left: 2px solid white;
        left: unset;
        right: 0;
        .carousel-control-prev-icon {
            background-image: escape-svg($carousel-control-next-icon-bg);
        }
    }
    html[dir='rtl'] &.carousel-control-next {
        border-radius: 0.25rem 0 0 0.25rem;
        margin-left: unset;
        margin-right: 1px;
        border-left: unset;
        border-right: 2px solid white;
        left: 0;
        right: unset;
        .carousel-control-next-icon {
            background-image: escape-svg($carousel-control-prev-icon-bg);
        }
    }
}

/* ##### PROGRESS BAR ##### */

.progress-bar {
    background-color: transparent;
    flex-direction: row;
    border: 1px solid back;
    border-radius: 5px;
    overflow: hidden;
    //white-space: normal;
    justify-content: left;
    .progress-section {
        color: var(--color-black);
        background-color: var(--color-white);
        margin: 1px;
        .progress-element-label {
            @extend .font-family-headers;
            font-size: 1rem;
            padding-top: 0.25rem;
        }
        .progress-element-set {
            padding: 0;
            .progress-element {
                padding: 0;
                margin: 0;
                i {
                    color: var(--color-white);
                    min-height: 10px;
                    //background-color: var(--color-white); // was making the information icon have a white box
                    width: 100%;
                    display: block;
                }
                i.active {
                    color: var(--color-black);
                }
                .progress-element-bar {
                    background-color: var(--pulse-green);
                    border: solid 1px var(--pulse-green);
                    border-left: solid 1px #60b55b;
                    margin: 0;
                    &.unfilled {
                        background-color: #e8f4e8;
                    }
                    &.notequal {
                        background-color: var(--pulse-warning);
                        &.optional {
                            background-color: var(--yellow);
                        }
                    }
                    &.html {
                        background-color: var(--color-white);
                    }
                    &.errors {
                        background-color: #f8d7da;
                    }
                }
            }
        }
        &:first-child .progress-element-set .progress-element:first-child .progress-element-bar {
            border-radius: 200px 0 0 200px;
        }
        &:last-child .progress-element-set .progress-element:last-child .progress-element-bar {
            border-radius: 0 200px 200px 0;
        }
        html[dir='rtl']
            &:first-child
            .progress-element-set
            .progress-element:first-child
            .progress-element-bar {
            border-radius: 0 200px 200px 0;
        }
        html[dir='rtl']
            &:last-child
            .progress-element-set
            .progress-element:last-child
            .progress-element-bar {
            border-radius: 200px 0 0 200px;
        }
    }
}

// From scroller-panel
.card {
    .row {
        margin: 0;
    }
    &.faller {
        background-color: var(--color-white);
        color: var(--color-black);
    }
    &.scroller {
        background-color: lightgrey;
        color: red;
        border-color: grey;
        .card-header {
            display: none;
        }
    }
    .card-header {
        &.faller {
            background-color: var(--color-white);
            color: var(--color-black);
        }
        .icon-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: large;
            border: grey solid 1px;
            height: 40px;
            width: 40px;
            border-radius: 40px;
            margin-right: 10px;
        }
        .collapsed-icon {
            display: none;
        }
        .expanded-icon {
            display: inline-flex;
        }
        &.collapsed {
            .collapsed-icon {
                display: inline-flex;
            }
            .expanded-icon {
                display: none;
            }
        }
    }
    .card-body {
        /* margin: 0 10px; */
        .search-init {
            text-align: right;
            h2 {
                font-size: 1.4rem;
                margin-bottom: 0px;
                cursor: var(--cursor-pointer);
                &:hover {
                    i {
                        transition: 0.4s;
                        color: var(--client-accent-color1);
                    }
                }
            }
        }
        .list-group-item {
            z-index: 1;
            border-right-width: 0;
            border-left-width: 0;
            border-color: grey;
            background-color: var(--color-white);
            color: var(--color-black);
            &.scroller {
                &:not(.active) {
                    background-color: lightgrey;
                    color: grey;
                }
            }
            input {
                &[disabled],
                .faller {
                    border: none;
                    background-color: transparent;
                }
            }
            &.active {
                z-index: 999;
            }
            .dropdown-element {
                input {
                }
            }
            .actions {
                i {
                    display: flex;
                    position: absolute;
                    justify-content: center;
                    align-items: center;
                    font-size: large;
                    color: grey;
                    //border: grey solid 1px;
                    height: 42px;
                    width: 42px;
                    border-radius: 42px;
                    background-color: var(--color-white);
                    z-index: 1000;
                    &.disabled {
                        color: lightgrey;
                        border-color: lightgrey;
                    }
                }
                &.scroller {
                    float: right;
                    .action-up {
                        top: -17px;
                        &:not(.disabled) {
                            color: var(--client-accent-color1);
                            border-color: var(--client-accent-color1);
                            &:hover {
                                color: var(--color-white);
                                background-color: var(--client-accent-color1);
                            }
                        }
                    }
                    .action-dn {
                        position: absolute;
                        bottom: -17px;
                        &:not(.disabled) {
                            color: var(--client-accent-color1);
                            border-color: var(--client-accent-color1);
                            &:hover {
                                color: var(--color-white);
                                background-color: var(--client-accent-color1);
                            }
                        }
                    }
                    .action-delete {
                        position: absolute;
                        bottom: -17px;
                        right: 120px;
                        &:not(.disabled) {
                            &:hover {
                                color: orange;
                            }
                        }
                    }
                    .action-edit {
                        position: absolute;
                        bottom: -17px;
                        right: 80px;
                    }
                    .action-add {
                        position: absolute;
                        bottom: -17px;
                        right: 20px;
                    }
                }
                // &.faller {
                //     // float: right;
                //     align-self: center;
                //     margin-left: auto;
                //     .action-edit {
                //         position: relative;
                //         // top: 5px;
                //         // right: 10px;
                //         &:hover {
                //             color: white;
                //             background-color: blue;
                //         }
                //     };
                // }
                &.faller {
                    @include flex-items-center;
                    .action-edit {
                        font-size: 1.125rem;
                        color: var(--color-grey-87);
                        @include trans-3;
                        cursor: var(--cursor-pointer);
                        @include pl(2);
                        position: relative;
                        border: none;
                        width: auto;
                        height: 100%;
                        &:hover {
                            color: var(--color-black);
                        }
                    }
                }
            }
        }
    }
    .hidden {
        display: none;
    }
}

// scroller-elements-fall
.app-scroller-elements-fall {
    .fallerNoAnswer {
        color: var(--color-grey-70);
        /* opacity: .5; */
    }
    // .list-group-item {
    //     padding: .75rem .5rem;
    //     border-width: 0 0 1px 0;
    //     border-color: var(--color-grey-e1);
    //     border-style: dotted;
    // }
    .d-faller {
        @include flex-between-center;
        width: 100%;
        .app-scroller-panel {
            width: 100%;
        }
        .survey-in-survey-element {
            width: 100%;
            @include flex-between-center;
            i {
                &:not(:first-child) {
                    margin-left: 5px;
                }
                &:hover {
                    cursor: var(--cursor-pointer);
                    color: var(--pulse-green);
                    transition: var(--ease-in-out-2s);
                }
            }
            @include tablet {
                flex-direction: column;
                .verticalLeft {
                    width: auto;
                }
            }
        }
        .dropdown-element {
            @include flex-between-center;
            width: 100%;
            .verticalLeft {
                width: auto;
                @include pr(2);
            }
            @include tablet {
                flex-direction: column;
            }
        }
        .text-array {
            @include flex-between-center;
            width: 100%;
        }
        .text-array-multiple {
            @include flex-between-center;
            width: 100%;
            .text-array-multiple__inline {
                @include flex-justify-end;
                align-items: center;
                width: 100%;
            }
            .text-array-multiple__sep {
                display: flex;
                flex-direction: column;
                .checkbox-choice {
                    @include flex-justify-end;
                    align-items: center;
                    @include mb(2);
                    &:last-child {
                        @include mb(0);
                    }
                    label {
                        @include pr(1);
                        @include pl(3);
                        @include m(0);
                        &::after {
                            content: ':';
                        }
                    }
                    input[type='text'],
                    input[type='date'] {
                        text-align: right;
                    }
                }
            }
            @include tablet {
                flex-direction: column;
                .text-array-multiple__inline {
                    justify-content: center;
                }
            }
            .compare-icon {
                color: var(--pulse-warning);
                &.optional {
                    color: var(--yellow);
                }
            }
        }
        .checkbox-array {
            @include flex-between-center;
            width: 100%;
            .vertical input[type='checkbox'].with-font ~ label:after {
                position: static;
                @include pl(2);
            }
            .checkbox-choice__singular {
                width: 100%;
                label {
                    span {
                        text-align: right;
                    }
                    @include flex-center-justify-end;
                    &:last-of-type {
                        @include mb(0);
                    }
                }
            }
            @include tablet {
                flex-direction: column;
                .verticalLeft {
                    width: auto;
                    @include mb(2);
                }
                .checkbox-choice {
                    flex-basis: auto;
                    @include mb(2);
                    border: solid red 5px;
                }
            }
        }
        .default-element {
            @include flex-between-center;
            width: 100%;
            .verticalLeft {
                width: auto;
                @include pr(2);
            }
            .default-element__input {
                input[type='text'] {
                    text-align: right;
                }
                input[type='date'] {
                    text-align: right;
                    // this is hacky
                    margin-right: -50px;
                }
            }
            @include tablet {
                flex-direction: column;
            }
        }
        .unit-measurement-element {
            @include flex-between-center;
            width: 100%;
            .verticalLeft {
                width: auto;
                @include pr(2);
            }
            @include tablet {
                flex-direction: column;
            }
        }
        .matrixdynamic-element {
            @include flex-between-center;
        }
        .image-picker-element {
            @include flex-between-center;
            width: 100%;
            label {
                display: block;
            }
        }
        .rating-element {
            @include flex-between-center;
            width: 100%;
            .verticalLeft {
                width: auto;
                @include pr(2);
            }
            .html-element {
                display: none;
            }
        }
        .radio-element {
            @include flex-items-center;
            width: 100%;
            justify-content: space-between;
            .radio-element__button {
                text-align: center;
                .with-font__radio-label {
                    @include px(2);
                }
            }
            .verticalLeft {
                width: auto;
                @include pr(2);
            }
            .vertical {
                width: auto;
                @include pl(2);
            }
            @include tablet {
                flex-direction: column;
                .radio-element__button {
                    @include mb(2);
                }
            }
        }
        .slider-element {
            @include flex-items-center;
            width: 100%;
            .slideContainerBoxH {
                display: none;
            }
            .slider-element__right {
                width: 100%;
                @include flex-justify-end;
                .html-element {
                    display: none;
                }
                .slidecontainer {
                    display: none;
                }
                .borderBox {
                    @include p(0);
                    display: block;
                    border: none;
                    span {
                        @include px(1);
                        line-height: 1.2;
                    }
                }
            }
            @include tablet {
                flex-direction: column;
                .slider-element__right {
                    .borderBox {
                        text-align: center;
                        width: 100%;
                    }
                }
            }
            .slider-element__rightEQ {
                // EQ Slider -----------------------------------------------------------------------------------
                @include flex-between-center;
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 1rem;
                .borderBoxEQ {
                    @include p(3);
                    //border: solid 1px var(--color-grey-c8);
                    //border-radius: 0.1875rem;
                    background-color: var(--color-black);
                    max-width: 8rem;
                    color: var(--color-white);
                    float: right;
                    margin-right: 3em;
                    span {
                        display: block;
                        text-align: center;
                        font-size: 1.5rem;
                        line-height: 1.2;
                        font-weight: bold;
                        &.borderBoxCaption {
                            font-size: 1rem;
                            //color: var(--color-grey-c3);
                            @include pb(2);
                            // white-space: nowrap;
                            font-weight: normal;
                        }
                    }
                }
                .slideContainerBox {
                    transform-origin: center;
                    transform: rotate(270deg);
                    margin-left: -17.5rem;
                    margin-right: -15rem;
                    // width: 100%;
                    // margin-top: 390px;
                }
                .slider-left-desc {
                    width: 60%;
                    height: 690px;
                    .html-element ul {
                        padding-inline-start: 10px;
                    }
                }
                .slidecontainer.sliderVerticalEQ {
                    position: relative;
                    width: 700px;
                    height: 30px;
                    //transform: rotate(270deg);
                    //-moz-transform: rotate(270deg);
                    /*do same for other browsers if required*/
                    .slideContainerBG {
                        position: absolute;
                        width: 90%;
                        height: 100%;
                        // background-color: orange;
                        display: flex;
                        left: 5%;
                        .slideContainerBG__item {
                            width: 100%;
                            height: 100%;
                            border-left: solid 2px var(--color-black);
                            &:last-child {
                                border-right: solid 2px var(--color-black);
                            }
                            // &:nth-child(even) {
                            //     border-left-color: white;
                            // }
                        }
                        .slideContainerBG__item2 {
                            width: 100%;
                            height: 50%;
                            border-left: solid 1px var(--color-black);
                            margin-top: 7px;
                            &:last-child {
                                height: 100%;
                                border-left: solid 0px var(--color-black);
                                border-right: solid 2px var(--color-black);
                                margin-top: 0px;
                            }
                            &:nth-child(99) {
                                border-right: solid 1px var(--color-black);
                            }
                        }
                    }
                    .slider-hoverLabelEQ {
                        transform: rotate(90deg);
                        position: absolute;
                        top: 50px;
                        .blue-arrow-sliderEQ {
                            color: #0072ed;
                            font-size: 2em;
                        }
                        .slider-hoverNumberEQ {
                            position: absolute;
                            left: 34px;
                            bottom: 6px;
                        }
                    }
                    .slideContainerNum {
                        position: absolute;
                        transform: rotate(90deg);
                        // border: solid 1px red;
                        left: 342px;
                        height: 644px;
                        top: -337px;
                        text-align: right;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        font-size: 0.6rem;
                    }
                    .slideContainerTop_Item {
                        transform: rotate(90deg);
                        left: 635px;
                        position: absolute;
                        width: 8rem;
                        font-weight: bold;
                    }
                    .slideContainerBottom_Item {
                        transform: rotate(90deg);
                        left: -60px;
                        position: absolute;
                        width: 8rem;
                        font-weight: bold;
                    }
                    input[type='range'] {
                        /*removes default webkit styles*/
                        -webkit-appearance: none;
                        /*fix for FF unable to apply focus style bug */
                        border: none;
                        /*required for proper track sizing in FF*/
                        width: 646px;
                        height: 30px;
                        background-color: rgb(0, 0, 0, 0%);
                        display: block;
                        position: absolute;
                        left: 28px;
                        top: 0px;
                        // overflow: hidden;
                    }
                    @-moz-document url-prefix() {
                        input[type='range'] {
                            width: 646px;
                            //-moz-transform: rotate(90deg);
                            left: 28px;
                            top: 0px;
                            //top: -63px;
                        }
                    }
                    input[type='range']::-webkit-slider-runnable-track {
                        width: 100%;
                        height: 4px;
                        background: rgb(0, 0, 0, 0%);
                        border: none;
                        border-radius: 4px;
                    }
                    input[type='range']::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        border: none;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        // border: solid 2px var(--pulse-dv-red);
                        background: var(--pulse-dv-red);
                        margin-top: -6px;
                        margin-right: 1px;
                        // box-shadow: -405px 0 0 400px pink;
                    }
                    input[type='range']:focus {
                        outline: none;
                    }
                    input[type='range']:focus::-webkit-slider-runnable-track {
                        //background: var(--color-grey-c3);
                    }
                    input[type='range']::-moz-range-track {
                        width: 4px;
                        height: 156px;
                        background: rgb(0, 0, 0, 0%);
                        border: none;
                        border-radius: 4px;
                    }
                    input[type='range']::-moz-range-thumb {
                        -webkit-appearance: none;
                        border: none;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        // border: solid 2px var(--pulse-dv-red);
                        background: var(--pulse-dv-red);
                        margin-top: -6px;
                    }
                    /*hide the outline behind the border*/
                    input[type='range']:-moz-focusring {
                        outline-offset: -1px;
                    }
                }
            }
        }
        .vertical {
            input[type='radio'] {
                & ~ label {
                    margin-bottom: 0;
                    &::after {
                        display: none;
                    }
                }
                &:not(:checked) {
                    & ~ label {
                        display: none !important;
                    }
                }
            }
        }
    }
}

.app-scroller-elements-scroll {
    //scroller-elements-scroll
    .d-scroller {
        .dropdown-element {
            @include flex-between-center;
            @include tablet {
                flex-direction: column;
                .verticalLeft {
                    width: auto;
                    @include mb(3);
                }
            }
        }
        .survey-in-survey-element {
            @include flex-between-center;
            i {
                &:not(:first-child) {
                    margin-left: 5px;
                }
                &.fa-minus-circle {
                    &:hover {
                        color: var(--pulse-danger);
                    }
                }
                &:hover {
                    cursor: var(--cursor-pointer);
                    color: var(--pulse-green);
                    transition: var(--ease-in-out-2s);
                }
            }
            @include tablet {
                flex-direction: column;
                .verticalLeft {
                    width: auto;
                    @include mb(3);
                }
            }
        }
        .text-array {
            @include flex-between-center;
            @include tablet {
                flex-direction: column;
                .verticalLeft {
                    width: auto;
                }
                .text-array-multiple__sep {
                    @include mt(3);
                }
            }
        }
        .text-array-multiple {
            @include flex-between-center;
            width: 100%;
            .text-array-multiple__sep {
                display: flex;
                flex-direction: column;
                .checkbox-choice {
                    @include flex-justify-end;
                    @include mb(2);
                    align-items: center;
                    &:last-child {
                        @include mb(0);
                    }
                    label {
                        @include pr(1);
                        @include pl(3);
                        @include m(0);
                        white-space: nowrap;
                        &::after {
                            content: ':';
                        }
                    }
                }
            }
            @include tablet {
                flex-direction: column;
                .verticalLeft {
                    width: auto;
                }
                .text-array-multiple__sep {
                    @include mt(3);
                }
            }
        }
        .checkbox-array {
            display: flex;
            align-items: flex-start;
            &:last-of-type {
                @include mb(0);
            }
            //@include flex-between-center;
            @include mb(3);
            .element-order__text {
                //width: 400px;
                width: auto;
            }
            .with-font__radio-label {
                @include flex-items-center;
                @include ml(0);
                @include py(1);
                @include pr(4);
                @include pl(4);
                position: relative;
                width: 100%;
                text-align: right;
            }
            .vertical {
                &.add-vertical-scroll {
                    height: 208px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    border-right: 5px solid var(--pulse-dv-orange-opacity);
                }
                html[dir='rtl'] &.add-vertical-scroll {
                    border-right: unset;
                    border-left: 5px solid var(--pulse-dv-orange-opacity);
                }
                &:last-child {
                    @include mb(0);
                }
            }
            .checkbox-choice {
                padding-left: 0px !important;
                label {
                    padding: 4px 45px 4px 45px !important;
                    justify-content: flex-end;
                    &:hover {
                        transition: var(--ease-in-out-2s);
                        background-color: var(--pulse-green-hover);
                        cursor: var(--cursor-pointer);
                    }
                }
                width: 33.33%;
                @include pl(3);
                @include mb(2);
                &.checkbox-choice__singular {
                    &:last-child label {
                        @include m(0);
                        @include py(0);
                    }
                }
                &:last-of-type {
                    @include mb(0);
                }
            }
            @include tablet {
                flex-direction: column;
                align-items: center;
                .element-order__text {
                    width: auto;
                }
                .verticalLeft {
                    width: auto;
                    @include mb(3);
                }
                .checkbox-choice {
                    //@include mb(4);
                }
            }
            @include breakpoint(xs) {
                .checkbox-choice {
                    width: 100%;
                }
            }
        }
        .date-element {
        }
        .default-element {
            @include flex-between-center;
            .verticalLeft {
                width: auto;
                @include pr(2);
            }
            .default-element__input {
                // border: solid 1px red;
            }
            @include tablet {
                flex-direction: column;
                .verticalLeft {
                    width: auto;
                    @include mb(3);
                }
            }
        }
        .image-picker-element {
            @include flex-between-center;
            label {
                display: block;
            }
        }
        .radio-element {
            @include flex-between-center;
            .radio-element__button {
                label {
                    padding: 4px 45px 4px 45px;
                    justify-content: flex-end;
                    &:hover {
                        transition: var(--ease-in-out-2s);
                        background-color: var(--pulse-green-hover);
                        cursor: var(--cursor-pointer);
                    }
                }
                @include flex-items-center;
                .with-font__radio-label {
                    @include flex-items-center;
                    //@include ml(3);
                    @include py(1);
                    //@include pl(2);
                    position: relative;
                }
            }
            .vertical {
                &.add-vertical-scroll {
                    height: 208px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    border-right: 5px solid var(--pulse-dv-orange-opacity);
                }
            }
            @include tablet {
                flex-direction: column;
                .verticalLeft {
                    //width: 100%;
                    width: auto;
                    @include mb(3);
                }
                .vertical {
                    width: 100%;
                }
                .radio-element__button {
                    @include mb(2);
                }
                .radio-element__button {
                    label.with-font__radio-label {
                        //@include pr(4);
                        @include ml(0);
                    }
                }
            }
            @include breakpoint(xs) {
                .d-flex.horizontal {
                    flex-wrap: wrap;
                    .radio-element__button {
                        width: 50%;
                    }
                }
            }
        }
        .radio-element.eq5d-left-align {
            display: flex;
            align-items: start;
            width: 100%;
            flex-direction: column;
            justify-content: space-between;
            .radio-element__button {
                label {
                    padding: 4px 9px 4px 9px;
                    justify-content: flex-start;
                    &:hover {
                        transition: var(--ease-in-out-2s);
                        background-color: var(--pulse-green-hover);
                        cursor: var(--cursor-pointer);
                    }
                }
                @include flex-items-center;
                .with-font__radio-label {
                    @include flex-items-center;
                    //@include ml(3);
                    @include py(1);
                    //@include pl(2);
                    position: relative;
                }
            }
            .vertical {
                &.add-vertical-scroll {
                    height: 208px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    border-right: 5px solid var(--pulse-dv-orange-opacity);
                }
            }
            @include tablet {
                flex-direction: column;
                .verticalLeft {
                    //width: 100%;
                    width: auto;
                    @include mb(3);
                }
                .vertical {
                    width: 100%;
                }
                .radio-element__button {
                    @include mb(2);
                }
                .radio-element__button {
                    label.with-font__radio-label {
                        //@include pr(4);
                        @include ml(0);
                    }
                }
            }
            @include breakpoint(xs) {
                .d-flex.horizontal {
                    flex-wrap: wrap;
                    .radio-element__button {
                        width: 50%;
                    }
                }
            }
        }
        .unit-measurement-element {
            @include flex-between-center;
            .d-flex.align-items-center {
                width: 100%;
            }
            @include tablet {
                flex-direction: column;
                .d-flex.align-items-center {
                    width: auto;
                    @include mb(3);
                }
            }
        }
        .rating-element {
            @include flex-between-center;
            .rating-element__right {
                @include flex-between-center;
            }
            label {
                cursor: var(--cursor-pointer);
                background-color: var(--color-grey-e1);
                border-style: solid;
                border-width: 1px 1px 1px 0;
                border-color: var(--color-grey-c8);
                transition: var(--trans);
                &.active {
                    background-color: var(--pulse-green);
                    color: var(--color-white);
                    border-color: var(--pulse-green);
                    font-weight: 500;
                }
                input {
                    visibility: hidden;
                    width: 0;
                }
                &:first-of-type {
                    border-radius: 0.25rem 0 0 0.25rem;
                    border-width: 1px;
                }
                &:last-of-type {
                    border-radius: 0 0.25rem 0.25rem 0;
                }
                &:hover:not(.active) {
                    background-color: var(--color-white);
                }
            }
            @include tablet {
                flex-direction: column;
                .verticalLeft {
                    .rating-element__right {
                        width: auto !important;
                    }
                    width: auto;
                    @include mb(3);
                }
            }
            @include breakpoint(xs) {
                .rating-element__right {
                    @include px(0);
                }
                label {
                    padding: 10px;
                }
            }
        }
        .slider-element {
            @include flex-items-center;
            & > div {
                // border: solid 1px red;
            }
            .verticalLeft {
                width: 35%;
            }
            &.sliderVertical {
                min-height: 160px;
            }
            &.sliderHorizontal {
                .verticalLeft {
                    width: 20%;
                }
                .slider-element__right {
                    font-size: 0.875rem;
                }
            }
            .slider-element__right {
                @include flex-between-center;
                width: 100%;
                .borderBox {
                    @include p(3);
                    border: solid 1px var(--color-grey-c8);
                    border-radius: 0.1875rem;
                    span {
                        display: block;
                        text-align: center;
                        font-size: 3rem;
                        line-height: 1.2;
                        &.borderBoxCaption {
                            font-size: 0.625rem;
                            color: var(--color-grey-c3);
                            @include pb(2);
                            white-space: nowrap;
                        }
                    }
                }
                .slider-element__right-holder {
                    display: flex;
                    align-items: center;
                    .slideContainerBox {
                        width: 90px;
                        position: relative;
                    }
                }
                .slideContainerBoxH {
                    width: 50%;
                    @include mx(3);
                    position: relative;
                    .slideContainerBG {
                        position: absolute;
                        width: calc(100% - 14px);
                        left: 7px;
                        display: flex;
                        top: calc(50% + 7px);
                        height: 7px;
                        .slideContainerBG__item {
                            border-left: solid 1px var(--color-grey-af);
                            width: 100%;
                            &:last-child {
                                border-right: solid 1px var(--color-grey-af);
                            }
                        }
                    }
                    .slideContainerNum {
                        @include flex-between-center;
                        position: absolute;
                        width: calc(100% - 20px);
                        font-size: 0.5rem;
                        top: auto;
                        bottom: -16px;
                        left: 10px;
                    }
                    input[type='range'] {
                        -webkit-appearance: none;
                        border: none;
                        width: 100%;
                        display: block;
                        position: relative;
                        z-index: 2;
                    }
                    input[type='range']::-webkit-slider-runnable-track {
                        width: 100%;
                        height: 4px;
                        background: var(--color-grey-c8);
                        border: none;
                        border-radius: 4px;
                    }
                    input[type='range']::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        border: none;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        border: solid 2px var(--pulse-green);
                        background: var(--color-white);
                        margin-top: -6px;
                        // box-shadow: -405px 0 0 400px pink;
                    }
                    input[type='range']:focus {
                        outline: none;
                    }
                    input[type='range']:focus::-webkit-slider-runnable-track {
                        background: var(--color-grey-c3);
                    }
                    input[type='range']::-moz-range-track {
                        // height: 4px;
                        // background: var(--color-grey-c8);
                        // border: none;
                        // border-radius: 4px;
                        height: 4px;
                        cursor: var(--cursor-pointer);
                        background: var(--color-grey-c8);
                        border: none;
                    }
                    input[type='range']::-moz-range-thumb {
                        -webkit-appearance: none;
                        border: none;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        border: solid 2px var(--pulse-green);
                        background: var(--color-white);
                        margin-top: -6px;
                    }
                    /*hide the outline behind the border*/
                    input[type='range']:-moz-focusring {
                        outline-offset: -1px;
                    }
                }
                // <div class='slider-element__right-holder'>
                // <div class="border">{{ element.formControl.value }}</div>
                // <div class="slideContainerBox">
                .slidecontainer.sliderHorizontal {
                }
                .slidecontainer.sliderVertical {
                    position: relative;
                    width: 150px;
                    height: 30px;
                    transform: rotate(270deg);
                    -moz-transform: rotate(270deg);
                    /*do same for other browsers if required*/
                    .slideContainerBG {
                        position: absolute;
                        width: 90%;
                        height: 100%;
                        // background-color: orange;
                        display: flex;
                        left: 5%;
                        .slideContainerBG__item {
                            width: 100%;
                            height: 100%;
                            border-left: solid 1px #e0e0e0;
                            &:last-child {
                                border-right: solid 1px #e0e0e0;
                            }
                            // &:nth-child(even) {
                            //     border-left-color: white;
                            // }
                        }
                    }
                    .slideContainerNum {
                        position: absolute;
                        transform: rotate(90deg);
                        // border: solid 1px red;
                        left: 68px;
                        height: 146px;
                        top: -85px;
                        text-align: right;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        font-size: 0.5rem;
                    }
                    input[type='range'] {
                        /*removes default webkit styles*/
                        -webkit-appearance: none;
                        /*fix for FF unable to apply focus style bug */
                        border: none;
                        /*required for proper track sizing in FF*/
                        width: 150px;
                        background-color: var(--color-grey-c8);
                        display: block;
                        position: absolute;
                        left: 0px;
                        top: 13px;
                        // overflow: hidden;
                    }
                    @-moz-document url-prefix() {
                        input[type='range'] {
                            width: 4px;
                            -moz-transform: rotate(90deg);
                            height: 156px;
                            left: 73px;
                            top: -63px;
                        }
                    }
                    input[type='range']::-webkit-slider-runnable-track {
                        width: 100%;
                        height: 4px;
                        background: var(--color-grey-c8);
                        border: none;
                        border-radius: 4px;
                    }
                    input[type='range']::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        border: none;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        border: solid 2px var(--pulse-green);
                        background: var(--color-white);
                        margin-top: -6px;
                        // box-shadow: -405px 0 0 400px pink;
                    }
                    input[type='range']:focus {
                        outline: none;
                    }
                    input[type='range']:focus::-webkit-slider-runnable-track {
                        background: var(--color-grey-c3);
                    }
                    input[type='range']::-moz-range-track {
                        width: 4px;
                        height: 156px;
                        background: var(--color-grey-c8);
                        border: none;
                        border-radius: 4px;
                    }
                    input[type='range']::-moz-range-thumb {
                        -webkit-appearance: none;
                        border: none;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        border: solid 2px var(--pulse-green);
                        background: var(--color-white);
                        margin-top: -6px;
                    }
                    /*hide the outline behind the border*/
                    input[type='range']:-moz-focusring {
                        outline-offset: -1px;
                    }
                }
            }
            @include tablet {
                flex-direction: column;
                .verticalLeft {
                    width: auto;
                }
                .slider-element__right {
                    display: block;
                    .slideContainerBoxH {
                        width: auto;
                        @include my(4);
                    }
                }
                .slider-element__rightEQ {
                    margin-top: 3rem !important;
                    .slideContainerBox {
                        margin-right: -17rem;
                    }
                }
            }
            .slider-element__rightEQ {
                // EQ Slider -----------------------------------------------------------------------------------
                @include flex-between-center;
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 1rem;
                .borderBoxEQ {
                    @include p(3);
                    //border: solid 1px var(--color-grey-c8);
                    //border-radius: 0.1875rem;
                    background-color: var(--color-black);
                    max-width: 8rem;
                    color: var(--color-white);
                    float: right;
                    margin-right: 3em;
                    span {
                        display: block;
                        text-align: center;
                        font-size: 1.5rem;
                        line-height: 1.2;
                        font-weight: bold;
                        &.borderBoxCaption {
                            font-size: 1rem;
                            //color: var(--color-grey-c3);
                            @include pb(2);
                            // white-space: nowrap;
                            font-weight: normal;
                        }
                    }
                }
                .slideContainerBox {
                    transform-origin: center;
                    transform: rotate(270deg);
                    margin-left: -17.5rem;
                    margin-right: -15rem;
                    // width: 100%;
                    // margin-top: 390px;
                }
                .slider-left-desc {
                    width: 60%;
                    height: 690px;
                    .html-element ul {
                        padding-inline-start: 10px;
                    }
                }
                .slidecontainer.sliderVerticalEQ {
                    position: relative;
                    width: 700px;
                    height: 30px;
                    //transform: rotate(270deg);
                    //-moz-transform: rotate(270deg);
                    /*do same for other browsers if required*/
                    .slideContainerBG {
                        position: absolute;
                        width: 90%;
                        height: 100%;
                        // background-color: orange;
                        display: flex;
                        left: 5%;
                        .slideContainerBG__item {
                            width: 100%;
                            height: 100%;
                            border-left: solid 2px var(--color-black);
                            &:last-child {
                                border-right: solid 2px var(--color-black);
                            }
                            // &:nth-child(even) {
                            //     border-left-color: white;
                            // }
                        }
                        .slideContainerBG__item2 {
                            width: 100%;
                            height: 50%;
                            border-left: solid 1px var(--color-black);
                            margin-top: 7px;
                            &:last-child {
                                height: 100%;
                                border-left: solid 0px var(--color-black);
                                border-right: solid 2px var(--color-black);
                                margin-top: 0px;
                            }
                            &:nth-child(99) {
                                border-right: solid 1px var(--color-black);
                            }
                        }
                    }
                    .slider-hoverLabelEQ {
                        transform: rotate(90deg);
                        position: absolute;
                        top: 50px;
                        .blue-arrow-sliderEQ {
                            color: #0072ed;
                            font-size: 2em;
                        }
                        .slider-hoverNumberEQ {
                            position: absolute;
                            left: 34px;
                            bottom: 6px;
                        }
                    }
                    .slideContainerNum {
                        position: absolute;
                        transform: rotate(90deg);
                        // border: solid 1px red;
                        left: 342px;
                        height: 644px;
                        top: -337px;
                        text-align: right;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        font-size: 0.6rem;
                    }
                    .slideContainerTop_Item {
                        transform: rotate(90deg);
                        left: 635px;
                        position: absolute;
                        width: 8rem;
                        font-weight: bold;
                    }
                    .slideContainerBottom_Item {
                        transform: rotate(90deg);
                        left: -60px;
                        position: absolute;
                        width: 8rem;
                        font-weight: bold;
                    }
                    input[type='range'] {
                        /*removes default webkit styles*/
                        -webkit-appearance: none;
                        /*fix for FF unable to apply focus style bug */
                        border: none;
                        /*required for proper track sizing in FF*/
                        width: 646px;
                        height: 30px;
                        background-color: rgb(0, 0, 0, 0%);
                        display: block;
                        position: absolute;
                        left: 28px;
                        top: 0px;
                        // overflow: hidden;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                    @-moz-document url-prefix() {
                        input[type='range'] {
                            width: 646px;
                            //-moz-transform: rotate(90deg);
                            left: 28px;
                            top: 0px;
                            //top: -63px;
                        }
                    }
                    input[type='range']::-webkit-slider-runnable-track {
                        width: 100%;
                        height: 4px;
                        background: rgb(0, 0, 0, 0%);
                        border: none;
                        border-radius: 4px;
                    }
                    input[type='range']::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        border: none;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        // border: solid 2px var(--pulse-dv-red);
                        background: var(--pulse-dv-red);
                        margin-top: -6px;
                        margin-right: 1px;
                        // box-shadow: -405px 0 0 400px pink;
                    }
                    input[type='range']:focus {
                        outline: none;
                    }
                    input[type='range']:focus::-webkit-slider-runnable-track {
                        //background: var(--color-grey-c3);
                    }
                    input[type='range']::-moz-range-track {
                        width: 4px;
                        height: 156px;
                        background: rgb(0, 0, 0, 0%);
                        border: none;
                        border-radius: 4px;
                    }
                    input[type='range']::-moz-range-thumb {
                        -webkit-appearance: none;
                        border: none;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        // border: solid 2px var(--pulse-dv-red);
                        background: var(--pulse-dv-red);
                        margin-top: -6px;
                    }
                    /*hide the outline behind the border*/
                    input[type='range']:-moz-focusring {
                        outline-offset: -1px;
                    }
                }
            }
        }
        &.d-scroller-top {
        }
        &.d-scroller-center {
        }
        &.d-scroller-bottom {
        }
    }
}

.faller {
    .card {
        .card-body {
            margin: 0;
            .list-group-item {
                padding: 0.75rem 0.5rem;
                border-width: 0 0 1px 0;
                border-bottom-color: var(--color-grey-e1);
                border-style: dotted;
                color: var(--color-black);
            }
        }
    }
    .element-order {
        width: auto;
        min-width: 40px;
        padding-right: 0px;
        border-right: none;
        flex-shrink: 0;
        @include flex-between-center;
        color: var(--color-grey-c3);
        .fa {
            font-size: 0.75rem;
        }
        .element-before__req {
            @include flex-items-center;
        }
        .element-order__number {
            font-size: 1.25rem;
        }
    }
    .element-order__text {
        @include pl(2);
        color: var(--color-grey-c3);
    }
}

.collapseButton {
    transition: 0.3s;
}

.collapseButton.collapsed {
    transform: rotate(180deg);
}

.survey-heading {
    @include flex-between-center;
    h1 {
        @include tablet {
            @include pr(2);
            font-size: 1.625rem !important;
        }
    }
}

.survey-end-notes {
    @include tablet {
        button:not(:last-child) {
            @include mb(2);
        }
    }
    .disclaimer {
        font-size: 0.75rem;
        color: var(--color-grey-70);
    }
}

.scroller {
    .mr-n6,
    .scroll__small {
        padding: 1rem 1.25rem;
        @include tablet {
            padding: 1rem;
        }
    }
    // Removed and replaced for the .element-order specified below the following commented-out reference (H20-1018).
    /*
    .element-order {
        width: 70px;
        padding-right: 20px;
        border-right: dotted 1px #707070;
        min-height: 3rem;
        flex-shrink: 0;
        @include flex-between-center;
        .element-order__number {
            font-size: 2rem;
        }
    }
    */
    .element-order {
        //Newly added, replacing the above commented-out styles.  (H20-1018).
        width: auto;
    }
    .element-order__text {
        @include px(2);
    }
    select.form-control {
        width: auto;
        max-width: 75%;
    }
    .scroll__top {
        height: 75px;
        background: linear-gradient(0deg, white 0%, #fafafa 75%, #ededed 100%);
        border: solid 1px var(--color-grey-af);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow: hidden;
        border-radius: var(--spacer) var(--spacer) 0 0;
    }
    .scroll__active {
        min-height: 35vh;
        border-right: 1px solid var(--color-grey-af);
        border-left: 1px solid var(--color-grey-af);
        .list-group-item {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            &.active {
                background-color: var(--color-white);
                color: #3c3c3c;
            }
        }
    }
    .scroll__bottom {
        //height: 175px;
        max-height: 75px;
        background: linear-gradient(0deg, #ededed 0%, #fafafa 25%, white 100%);
        border: solid 1px var(--color-grey-af);
        border-radius: 0 0 var(--spacer) var(--spacer);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        overflow: hidden;
    }
    .scroll__small {
        opacity: 0.3;
        pointer-events: none;
        border-bottom: solid 1px var(--color-grey-c8);
        .element-order {
            .element-order__number {
                font-size: 2rem;
            }
        }
    }
    .mr-n6 {
        position: relative;
        padding-top: 2rem;
        padding-bottom: 2rem;
        min-height: 35vh;
        .actions {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            i {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: large;
                color: var(--color-grey-70);
                border: 2px solid var(--color-grey-af);
                height: 48px;
                width: 48px;
                border-radius: 50%;
                background-color: var(--color-white);
                z-index: 9999;
                &.disabled {
                    color: var(--color-grey-70);
                    border-color: var(--color-grey-70);
                }
                &:not(.disabled) {
                    transition: 0.35s;
                    cursor: var(--cursor-pointer);
                }
            }
            .scroller__active-buttons-top {
                top: -20px;
                right: 20px;
                position: absolute;
            }
            .scroller__active-buttons-bottom {
                bottom: 20px;
                right: 20px;
                position: absolute;
                display: flex;
                .scroller__active-buttons-bottom__left {
                    margin-right: 30px;
                }
            }
            .action-up {
                &:not(.disabled) {
                    border: 2px solid var(--color-grey-c8);
                    color: var(--client-accent-color1);
                    &:hover {
                        color: var(--color-white);
                        background-color: var(--client-accent-color1);
                        border-color: var(--client-accent-color1);
                    }
                }
            }
            .action-dn {
                &:not(.disabled) {
                    color: var(--color-white);
                    border: none;
                    background-color: var(--client-accent-color2);
                    &:hover {
                        background-color: var(--client-accent-color1);
                    }
                }
            }
            .action-delete {
                margin-right: calc(var(--spacer) * 1.5);
                &:not(.disabled) {
                    &:hover {
                        color: var(--pulse-unmatched);
                    }
                }
            }
            .action-edit {
            }
            .action-add {
                margin-right: calc(var(--spacer) * 1.5);
            }
        }
    }
    // .scroller specific Responsive control.
    @media (max-width: 991.98px) {
        select.form-control {
            max-width: 100%;
        }
    }
    @media (max-width: 575.98px) {
        select.form-control {
            width: 100%;
        }
    }
}

@include media-breakpoint-down(md) {
    // Responsive fix for NON-Bootstrap data grid tables that allows for horizontal scrolling. Comes in four flavors (widths). Use example: Use 1200px version for Tables with MANY columns, and use 576px version if you only have a few columns that are too tight).
    .data-table-ctrl-1200,
    .data-table-ctrl-992,
    .data-table-ctrl-768,
    .data-table-ctrl-576 {
        overflow-x: auto;
    }
    .data-table-ctrl-1200 {
        .ngx-datatable {
            width: 1200px;
        }
    }
    .data-table-ctrl-992 {
        .ngx-datatable {
            width: 992px;
        }
    }
    .data-table-ctrl-768 {
        .ngx-datatable {
            width: 768px;
        }
    }
    .data-table-ctrl-576 {
        .ngx-datatable {
            width: 576px;
        }
    }
}

@include media-breakpoint-up(xl) {
    .formbit-layout {
        /* Login-style Pages. */
        .tada {
            max-width: 500px;
        }
    }
}

@media (max-height: 479.98px) {
    /* Fix for positioning when screen too short (fixes top of login pages cut-off) */
    body.public-view {
        .container-fluid {
            top: 0 !important;
            transform: none !important;
        }
    }
}

.reg-page {
    // For Registration Page-Only (below). Fixes issues caused by smaller widths of the Reg page's left panel.
    .radio-element,
    .checkbox-array {
        flex-direction: column !important;
        .verticalLeft {
            width: auto;
        }
        .vertical {
            width: 100%;
        }
        .with-font__radio-label {
            padding-right: 45px !important;
        }
    }
    @media (min-width: 1120px) and (max-width: 1595.98px) {
        .dropdown-element {
            flex-direction: column !important;
        }
        .verticalLeft {
            width: auto;
            margin-bottom: 1rem;
        }
    }
}

.datepicker-hideMonthSelect {
    select.form-select:first-child {
        display: none;
    }
}

.datepicker-hideDays {
    ngb-datepicker-month {
        display: none;
    }
}

@include media-breakpoint-up(md) {
    .library-thumbnail {
        // Thumbnails seem to always be the same size
        display: flex;
        width: 116px;
    }
}

.was-validated .form-control:invalid,
.form-control:valid {
    padding-left: 0.75rem !important;
}

html[dir='rtl'] .float-end {
    float: left !important;
}

html[dir='rtl'] .subcat-details {
    text-align: right;
}

.gesture-dot-small {
    height: 25px;
    width: 25px;
    background-color: rgb(21, 163, 8, 0.5);
    border: 0.15em solid rgb(21, 163, 8);
    border-radius: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
}

.gesture-dot-medium {
    height: 32px;
    width: 32px;
    background-color: rgb(211, 214, 6, 0.5);
    border: 0.15em solid rgb(211, 214, 6);
    border-radius: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
}

.gesture-dot-large {
    height: 39px;
    width: 39px;
    background-color: rgb(255, 60, 0, 0.5);
    border: 0.15em solid rgb(255, 60, 0);
    border-radius: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
}
html[dir='rtl'] .form-check .form-check-input {
    float: right !important;
}

.img-overlay-wrap {
    position: relative;
    display: inline-block;
    transition: transform 150ms ease-in-out;
}

.img-overlay-wrap img {
    display: block;
}

.img-overlay-wrap svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.overlay-poly:hover {
    cursor: pointer;
    fill-opacity: 0.75;
    fill: #343434;
}

.overlay-poly {
    stroke: black;
    stroke-width: 2;
    fill: transparent;
}

.overlay-poly-selected {
    fill-opacity: 0.5;
    fill: #343434;
}

.alert-dismissible .btn-close {
    left: revert;
    padding: 1.1rem 1rem;
}

html[dir='rtl'] .alert-dismissible .btn-close {
    right: revert;
    padding: 1.1rem 1rem;
}

.pi-control-form-mode {
    margin-top: 14px !important;
    margin-bottom: 14px !important;
    background: #fff !important;
    border-radius: 15px !important;
    padding: 15px !important;
}

.pi-control-view-mode {
    margin-top: 8px !important;
}

.pi-lift {
    box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.tiny-text {
    font-size: 0.75rem;
}
.slider-element__rightEQ {
    // EQ Slider -----------------------------------------------------------------------------------
    @include flex-between-center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    .borderBoxEQ {
        @include p(3);
        //border: solid 1px var(--color-grey-c8);
        //border-radius: 0.1875rem;
        background-color: var(--color-black);
        max-width: 8rem;
        color: var(--color-white);
        float: right;
        margin-right: 3em;
        span {
            display: block;
            text-align: center;
            font-size: 1.5rem;
            line-height: 1.2;
            font-weight: bold;
            &.borderBoxCaption {
                font-size: 1rem;
                //color: var(--color-grey-c3);
                @include pb(2);
                // white-space: nowrap;
                font-weight: normal;
            }
        }
    }
    .slideContainerBox {
        transform-origin: center;
        transform: rotate(270deg);
        margin-left: -17.5rem;
        margin-right: -15rem;
        // width: 100%;
        // margin-top: 390px;
    }
    .slider-left-desc {
        width: 60%;
        height: 690px;
        .html-element ul {
            padding-inline-start: 10px;
        }
    }
    .slidecontainer.sliderVerticalEQ {
        position: relative;
        width: 700px;
        height: 30px;
        //transform: rotate(270deg);
        //-moz-transform: rotate(270deg);
        /*do same for other browsers if required*/
        .slideContainerBG {
            position: absolute;
            width: 90%;
            height: 100%;
            // background-color: orange;
            display: flex;
            left: 5%;
            .slideContainerBG__item {
                width: 100%;
                height: 100%;
                border-left: solid 2px var(--color-black);
                &:last-child {
                    border-right: solid 2px var(--color-black);
                }
                // &:nth-child(even) {
                //     border-left-color: white;
                // }
            }
            .slideContainerBG__item2 {
                width: 100%;
                height: 50%;
                border-left: solid 1px var(--color-black);
                margin-top: 7px;
                &:last-child {
                    height: 100%;
                    border-left: solid 0px var(--color-black);
                    border-right: solid 2px var(--color-black);
                    margin-top: 0px;
                }
                &:nth-child(99) {
                    border-right: solid 1px var(--color-black);
                }
            }
        }
        .slider-hoverLabelEQ {
            transform: rotate(90deg);
            position: absolute;
            top: 50px;
            .blue-arrow-sliderEQ {
                color: #0072ed;
                font-size: 2em;
            }
            .slider-hoverNumberEQ {
                position: absolute;
                left: 34px;
                bottom: 6px;
            }
        }
        .slideContainerNum {
            position: absolute;
            transform: rotate(90deg);
            // border: solid 1px red;
            left: 342px;
            height: 644px;
            top: -337px;
            text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 0.6rem;
        }
        .slideContainerTop_Item {
            transform: rotate(90deg);
            left: 635px;
            position: absolute;
            width: 9rem;
            font-weight: bold;
            text-align: center;
        }
        .slideContainerBottom_Item {
            transform: rotate(90deg);
            left: -70px;
            position: absolute;
            width: 9rem;
            font-weight: bold;
            text-align: center;
        }
        input[type='range'] {
            /*removes default webkit styles*/
            -webkit-appearance: none;
            /*fix for FF unable to apply focus style bug */
            border: none;
            /*required for proper track sizing in FF*/
            width: 646px;
            height: 30px;
            background-color: rgb(0, 0, 0, 0%);
            display: block;
            position: absolute;
            left: 28px;
            top: 0px;
            // overflow: hidden;
        }
        @-moz-document url-prefix() {
            input[type='range'] {
                width: 646px;
                //-moz-transform: rotate(90deg);
                left: 28px;
                top: 0px;
                //top: -63px;
            }
        }
        input[type='range']::-webkit-slider-runnable-track {
            width: 100%;
            height: 4px;
            background: rgb(0, 0, 0, 0%);
            border: none;
            border-radius: 4px;
        }
        input[type='range']::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: none;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            // border: solid 2px var(--pulse-dv-red);
            background: var(--pulse-dv-red);
            margin-top: -6px;
            margin-right: 1px;
            // box-shadow: -405px 0 0 400px pink;
        }
        input[type='range']:focus {
            outline: none;
        }
        input[type='range']:focus::-webkit-slider-runnable-track {
            //background: var(--color-grey-c3);
        }
        input[type='range']::-moz-range-track {
            width: 4px;
            height: 156px;
            background: rgb(0, 0, 0, 0%);
            border: none;
            border-radius: 4px;
        }
        input[type='range']::-moz-range-thumb {
            -webkit-appearance: none;
            border: none;
            height: 16px;
            width: 16px;
            border-radius: 50%;
            // border: solid 2px var(--pulse-dv-red);
            background: var(--pulse-dv-red);
            margin-top: -6px;
        }
        /*hide the outline behind the border*/
        input[type='range']:-moz-focusring {
            outline-offset: -1px;
        }
    }
}

.pulse-warning {
    color: var(--pulse-warning);
}

.row > svd-splitter {
    width: 4px;
}

div.jsoneditor.jsoneditor-mode-code {
    height: 700px !important;
}

.ag-theme-quartz .ag-header-cell {
    font-weight: bolder;
    text-transform: uppercase;
}

.ag-row.ag-row-focus {
    z-index: 1;

    .ag-cell span {
        overflow: hidden;
    }

    span.ag-cell-value:has(.dropdown-menu) {
        overflow: visible;
    }
}

.ag-cell.ag-cell-focus {
    z-index: 1;

    .ag-cell-value span {
        overflow: hidden;
    }

    span.ag-cell-value:has(.dropdown-menu) {
        overflow: visible;
    }
}
