:root {
    --font-size-72: 4.5rem;
    --font-size-64: 4rem;
    --font-size-60: 3.75rem;
    --font-size-48: 3rem;
    --font-size-40: 2.5rem;
    --font-size-36: 2.25rem;
    --font-size-32: 2rem;
    --font-size-30: 1.875rem;
    --font-size-26: 1.625rem;
    --font-size-24: 1.5rem;
    --font-size-21: 1.3125rem;
    --font-size-20: 1.25rem;
    --font-size-18: 1.125rem;
    --font-size-16: 1rem;
    --font-size-14: 0.875rem;
    --font-size-12: 0.75rem;
    --font-size-10: 0.625rem;
}

body {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .text-xlg,
    .text-lg,
    .text-md,
    .text-sm,
    .text-xsm {
        &.regular {
            font-weight: 400;
        }

        &.medium {
            font-weight: 500;
        }

        &.semi-bold {
            font-weight: 600;
        }

        &.bold {
            font-weight: 700;
        }
    }

    h1 {
        @extend .font-family-headers;
        font-size: var(--font-size-72);
        line-height: 5.625rem;
    }
    h2 {
        @extend .font-family-headers;
        font-size: var(--font-size-60);
        line-height: 4.5rem;
    }
    h3 {
        @extend .font-family-headers;
        font-size: var(--font-size-48);
        line-height: 3.75rem;
    }
    h4 {
        @extend .font-family-headers;
        font-size: var(--font-size-36);
        line-height: 2.75rem;
    }
    h5 {
        @extend .font-family-headers;
        font-size: var(--font-size-30);
        line-height: 2.375rem;
    }
    h6 {
        @extend .font-family-headers;
        font-size: var(--font-size-24);
        line-height: 2rem;
    }

    .text-xlg {
        font-size: var(--font-size-20);
        line-height: 1.875rem;
    }

    .text-lg {
        font-size: var(--font-size-18);
        line-height: 1.75rem;
    }

    .text-md {
        font-size: var(--font-size-16);
        line-height: 1.5rem;
    }

    .text-sm {
        font-size: var(--font-size-14);
        line-height: 1.25rem;
    }

    .text-xsm {
        font-size: var(--font-size-12);
        line-height: 1.125rem;
    }
}
