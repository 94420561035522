@function map-get-or-key($map, $key) {
    @if map-has-key($map, $key) or map-has-key($map, -$key) {
        @if $key != 'auto' and type-of($key) == 'number' and $key < 0 {
            @return 0 - map-get($map, -$key);
        } @else {
            @return map-get($map, $key);
        }
    } @else if type-of($key) == 'string' {
        @return unquote($key);
    } @else {
        @return $key;
    }
}

@function bsize($key) {
    @return map-get-or-key($spacers, $key);
}

@mixin m($space) {
    margin: bsize($space);
}

@mixin mt($space) {
    margin-top: bsize($space);
}

@mixin mb($space) {
    margin-bottom: bsize($space);
}

@mixin ml($space) {
    margin-left: bsize($space);
}

@mixin mr($space) {
    margin-right: bsize($space);
}

@mixin p($space) {
    padding: bsize($space);
}

@mixin pt($space) {
    padding-top: bsize($space);
}

@mixin pb($space) {
    padding-bottom: bsize($space);
}

@mixin pl($space) {
    padding-left: bsize($space);
}

@mixin pr($space) {
    padding-right: bsize($space);
}

@mixin mx($space) {
    @include ml($space);
    @include mr($space);
}

@mixin my($space) {
    @include mt($space);
    @include mb($space);
}

@mixin px($space) {
    @include pl($space);
    @include pr($space);
}

@mixin py($space) {
    @include pt($space);
    @include pb($space);
}

/*### Media Width Breakpoints ###*/

@mixin tablet {
    @media (max-width: 1024px) {
        @content;
    }
}
@mixin sm-desktop {
    @media (min-width: 1025px) and (max-width: 1199px) {
        @content;
    }
}
@mixin desktop {
    @media (min-width: 1200px) and (max-width: 1799px) {
        @content;
    }
}
@mixin big-desktop {
    @media (min-width: 1800px) {
        @content;
    }
}

@mixin breakpoint($class) {
    @if $class == xs {
        @media (max-width: 576px) {
            @content;
        }
    } @else if $class == sm {
        @media (min-width: 576px) {
            @content;
        }
    } @else if $class == md {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $class == lg {
        @media (min-width: 992px) {
            @content;
        }
    } @else if $class == xl {
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $class == xd {
        @media (min-width: 1600px) {
            @content;
        }
    } @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg, xl, xd";
    }
}

@mixin h1-gray {
    @extend .font-family-headers;
    /* @extend .color-5A; */
    @extend .font-size-32;
    letter-spacing: -1px;
}

@mixin trans-3 {
    transition: 0.3s;
}

@mixin flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-center-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-items-center {
    display: flex;
    align-items: center;
}

@mixin flex-justify-end {
    display: flex;
    justify-content: flex-end;
}

@mixin flex-center-justify-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@mixin border-inside {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

@mixin border-outside {
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
}
